import React from "react";
import {
  Box,
  Divider,
  Paper,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import useInventoryDetails from "./pro-datafetch/inventory";
import { PieChart } from "../UI-Helper-Components/Charts/Pie";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Invetory = () => {
  const { inventorydata } = useInventoryDetails();

  const data = {
    labels: ["Expenditure", "Revenue", "Balance"],
    datasets: [
      {
        label: "in Rs.",
        data: [inventorydata?.revenueStats?.Expenditure, inventorydata?.revenueStats?.Revenue, inventorydata?.revenueStats?.Balance],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  // console.log("inventory data", inventorydata)
  return (
    <Box>
      <Paper sx={{ margin: 2, padding: 2 }}>
        <Typography variant="h5" sx={{ margin: 2 }}>
        summary Section
        </Typography>
        <Divider />
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Recycled / Co-processed (MT)
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {inventorydata?.totalRecycled?.toFixed(3)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Allocated (MT)
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {inventorydata?.totalAllocated?.toFixed(3)}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4} sm={4}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Balanced (MT)
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {inventorydata?.totalBalenced?.toFixed(3)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Contracts with vendor
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {inventorydata?.totalVendors}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Contracts with customer
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {inventorydata?.totalCustomers}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Purchase Order - Vendor
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {inventorydata?.TotalPurchaseOrderVendor}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Purchase Order - Customer
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {inventorydata?.TotalPurchaseOrderCustomer}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="h6"
                sx={{ textTransform: "uppercase", textAlign: "start" }}
              >
                Revenue stats
              </Typography>
              <PieChart data={data} />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="h6"
                sx={{ textTransform: "uppercase", textAlign: "start" }}
              >
                Total Material types
              </Typography>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Typography>Material Types</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>Total Fullfilled (MT)</Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventorydata?.totalMaterialType?.map((item:any) =>{
                    return <StyledTableRow>
                    <StyledTableCell>
                      <Typography>{item?.materialType}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item?.totalFulfillments?.toFixed(3)}</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                  })}
                  
                  
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Invetory;
