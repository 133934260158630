

import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_INSIGHTS_BASE_URL;
const useWardWiseCollectionDetails = ({city, calendar,setScannedPropertiesInWard, setTotalPropertiesInWard }:{city:string, calendar:string,setScannedPropertiesInWard:any, setTotalPropertiesInWard:any}) => {
  const [collectionData, setCollectionData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [{
        label: "Scanned Collection",
        data: 0,
        borderColor: "#ADD8E6",
        backgroundColor: "#ADD8E6",
        maxBarThickness: 50
      },
      {
        label: "Total properties",
        data: 0,
        borderColor: "#ADD8E6",
        backgroundColor: "#23418D",
        maxBarThickness: 50
      },
    ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/collection/ward?city=${city}&to=${calendar}&from=${calendar}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        const wards = Object.keys(result.data.collectionDataByWard);
        const propertiesCovered = wards.map((date) => {
                  return Number(result.data.collectionDataByWard[date].propertiesCovered);
                });
                const totalProperties = wards.map((date) => {
                  return Number(result.data.collectionDataByWard[date].totalPropertiesInWard);
                });
              // console.log("propertiesCovered", propertiesCovered)
        if (!didCancel) {
          setScannedPropertiesInWard(propertiesCovered.reduce((a,b)=>a+b))
          setTotalPropertiesInWard(totalProperties.reduce((a,b)=>a+b))
          setCollectionData({
            labels: wards,
        datasets: [
          
          {
            label: "Scanned Collection",
            data: propertiesCovered,
            borderColor: "#ADD8E6",
            backgroundColor: "#ADD8E6",
            maxBarThickness: 50
          },
          {
            label: "Total properties",
            data: totalProperties,
            borderColor: "#ADD8E6",
            backgroundColor: "#23418D",
            maxBarThickness: 50
          },
        ],
          });
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, calendar]);

  return {  collectionData, loading, error };
};

export default useWardWiseCollectionDetails ;



