import React, { useState } from 'react'
import Sidebar from './Sidebar'
import PrimaryAppBar from './PrimaryAppBar'

import { Box, Container } from '@mui/material'
import VendorOnboarding from '../Vendor/VendorOnboarding'
import VenderOnboardingForm from '../Vendor/VenderOnboardingForm'
import VendorDisplay from "../Vendor/VendorDisplay"
import {Routes, Route, Outlet} from "react-router-dom"
// import MainContent from './MainContent'
const LandingPage = () => {
  const [open, setOpen] = useState(false)
  return (
      <div style={{}}>
        <PrimaryAppBar open={open} setOpen={setOpen} />
        <Box sx={{
          marginTop:"50px",
          marginLeft: !open ? "100px" : "250px",
          
        }}>
         
          {/* <VendorOnboarding /> */}
          
          {/* <VendorDisplay /> */}
          <Outlet />
        </Box>
        
        <Sidebar open={open} setOpen={setOpen} />
      
        
    </div>
  )
}

export default LandingPage