
import BarGraph from '../../Graphs/BarGraph'
import ScaleLoaderItem from '../../Ui-Elemnent/ScaleLoader';
import useSagrigationDetails from './data-fetch/fetchSegrigationData'
const SegregationVsCollectionChart = ({city, fromDate, toDate, setSegrigatedProperties, setScannedProperties, setTotalProperties }:{city:string, fromDate:string, toDate:string, setSegrigatedProperties:any, setTotalProperties:any, setScannedProperties:any}) => {
  const option = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        
        beginAtZero: true,
      },
    },
  }
  const  {sagrigationData, loading} = useSagrigationDetails({city:city, fromDate:fromDate, toDate:toDate, setSegrigatedProperties:setSegrigatedProperties, setTotalProperties:setTotalProperties, setScannedProperties:setScannedProperties});
  
  return (
    <div>
      {loading ? <ScaleLoaderItem loading={loading} /> : <BarGraph  chartData={sagrigationData} option={option}/> }
    </div>
    
  )
}
export default SegregationVsCollectionChart