import { Avatar, Button, Checkbox, FormControlLabel, Grid, Link, Paper, TextField, Typography } from '@mui/material'
import useUserLogin from './data-fetch/login-data';
import { Form, Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const {login} = useUserLogin()
 
  const paperStyle = {
    padding:50,
    // marginTop:"200px",
    // height:"35vh",
    width:320,
    
    margin:"10% auto"
  }
  const textFieledStyle = {
    padding:2,
    margin:3
  }

 
  return (
    <div >
    {/* <img src="/wallpaper.jpg" alt="wallpaer" /> */}
   <Grid>
    <Paper elevation={10} style={paperStyle}>
       
       
        <Grid >
        {/* <Avatar><LockOpenIcon /></Avatar> */}

         <Typography padding={2} align="center" variant='h4'>Sign In</Typography>
        </Grid>
          <Formik initialValues={{ email: '', password: '', rememberMe:false }}  onSubmit={async (values, { setSubmitting }) => {
         let loginUser = await login(JSON.stringify(values));
         if(!loginUser.success){
           console.log(loginUser)
          toast(loginUser.message, {type:"error", theme:"colored"});
         }else{
          toast("Login Successful", {type:"success", theme:"colored"});
          //  setLogedIn(loginUser.data)
         }
          setSubmitting(false);
       }}>
            {(props:any)=>(
              <Form>
                   <Field as={TextField} id="outlined-basic" label="Email" name='email' variant="outlined" type={'email'} fullWidth required style={textFieledStyle} />
                  <Field as={TextField} id="outlined-basic" label="Password" name='password' variant="outlined" type={'password'} fullWidth required style={textFieledStyle}/>
                  <Field as={FormControlLabel}  control={<Checkbox name='rememberMe' />}  label="Remember me" />
                  <Button style={{margin:4}} fullWidth type='submit' variant="contained" >Sign In</Button>

              </Form>
            )}
          </Formik>
      
       <Typography>
        <Link href='/user/forget-password'>
          Forgot Password?
        </Link>
       </Typography>
    </Paper>
    <ToastContainer />
   </Grid>
   </div>
  )
}

export default Login