import React, { useEffect, useState } from 'react'
import DateRangeSelector from '../../Ui-Elemnent/DateRange'
import AttendanceChart from './AttendanceChart'
import ScannedCollectionChart from './ScannedCollectionChart'
import SegregationVsCollectionChart from './SegragationVsCollectionChart'
import format from 'date-fns/format'
// const TabName = ["Scanned Collection", "Collection Vs Segregation", "Attendance" ]

const CityWiseData = ({city}:{city:string}) => {
  const [dayDiff, setDaydiff] =useState<any>()
  const today = new Date();
  const [selectedTab, setSelectedTab] =  useState("Scanned Collection");
  const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [showExtraInfo, setShowExtraInfo] = useState("");

  // data for Scanned Collection
  const [scannedProperties, setScannedProperties] = useState<number>(1);
  const [totalProperties, setTotalProperties] = useState<number>(1);
  //////
    // data for segrigation Collection
    const [segrigatedProperties, setSegrigatedProperties] = useState<number>(0);
    //////

    // data for Attendance
    const [totalUser, setTotalUser] = useState<number>(1);
    const [presentUser, setPresentUser] = useState<number>(0);
    //////
  const [range, setRange] = useState<any[]>([
    {
      endDate: new Date(),
      startDate: today.setDate(today.getDate()-6),
      key: 'selection', 
    }
  ])

  useEffect(()=>{

    setToDate(format(range[0].endDate, "yyyy-MM-dd"))
    setFormDate(format(range[0].startDate, "yyyy-MM-dd"))

    setDaydiff((range[0].endDate - range[0].startDate) / 86400000);

  },[range, toDate, fromDate]);
  
  // useEffect(()=>{
  //   console.log("scannedProperties", totalProperties);
  //   console.log("totalProperties", totalProperties);
    
  // },[totalProperties, scannedProperties])
  const TabName = [{tabName:"Scanned Collection", numerator: scannedProperties/(dayDiff + 1), denominator: totalProperties/(dayDiff + 1), extraInfo : "Shows the Number of properties scanned Vs. Number of properties registered. It will be shown as average when selected duration is more than a day."}, {tabName:"Segregation", numerator: segrigatedProperties/(dayDiff + 1), denominator: scannedProperties === 0 ? 1 : scannedProperties/(dayDiff + 1), extraInfo :"Shows the number of properties scanned Vs. number of properties where segregation happened. When the duration is more than a day, it will be average."}, {tabName:"Attendance", numerator: presentUser/(dayDiff + 1), denominator: totalUser/(dayDiff + 1), extraInfo: "Shows number of waste workers present out of the number of waste workers registered. When the selected timeline is more than a day, it will show the average."} ]
  const HandleOnMouseEnter = (tabName:string)=>{
    setShowExtraInfo(tabName)
  }
  const HandleOnMouseLeave = ()=>{
    setShowExtraInfo("")
  }
  return (
    <div className='w-full ml-2 h-1/3 '>
     <div className='flex justify-end mr-4'>
       <DateRangeSelector range={range} setRange={setRange}/>
      </div>
      <div className='border-2 border-t-0 bg-white'>
      <div className='flex justify-between'>
        {
          TabName.map((item, index)=>{
            return(
              <div  key={index} className={`h-12 border w-1/3 cursor-pointer hover:bg-lightBlue hover:text-recity py-2 border border-black  ${selectedTab === item.tabName ? "bg-lightBlue text-recity" : "bg-recity text-white"}`} onClick={()=>setSelectedTab(item.tabName)} onMouseEnter={()=>HandleOnMouseEnter(item.tabName)} onMouseLeave={HandleOnMouseLeave}> 
                <h1 className='text-xl font-medium text-center'>{item.tabName}{` (${Math.floor(item.numerator).toString()}/${Math.floor(item.denominator).toString()=== "1" ? "0": Math.floor(item.denominator).toString()}) (${Math.floor(!isNaN(Number(item.numerator)*100/Number(item.denominator)) ? Number(item.numerator)*100/Number(item.denominator) :0)}%)`} </h1>
                <div className={`relative text-sm rounded-lg bg-black text-white items-end mx-auto mt-2 py-4 ${showExtraInfo !== item.tabName ? "hidden": ""}`}>{item.extraInfo}</div>
              </div>
            )
          })
        }
      </div>
      <div>
      {
        selectedTab === "Segregation" ?  <SegregationVsCollectionChart city={city} toDate={toDate} fromDate={fromDate} setScannedProperties={setScannedProperties} setSegrigatedProperties={setSegrigatedProperties} setTotalProperties={setTotalProperties}/> : selectedTab === "Attendance" ? <AttendanceChart city={city} toDate={toDate} fromDate={fromDate} setTotalUser={setTotalUser} setPresentUser={setPresentUser}/> : <ScannedCollectionChart city={city} toDate={toDate} fromDate={fromDate} setScannedProperties={setScannedProperties} setTotalProperties={setTotalProperties}/>
      }    
    </div>
      <div className='hidden'>
      
         <SegregationVsCollectionChart city={city} toDate={toDate} fromDate={fromDate} setScannedProperties={setScannedProperties} setSegrigatedProperties={setSegrigatedProperties} setTotalProperties={setTotalProperties}/> 
         <AttendanceChart city={city} toDate={toDate} fromDate={fromDate} setTotalUser={setTotalUser} setPresentUser={setPresentUser}/> 
         <ScannedCollectionChart city={city} toDate={toDate} fromDate={fromDate} setScannedProperties={setScannedProperties} setTotalProperties={setTotalProperties}/>
          
    </div>
       
    
    </div>
    </div>
  )
}

export default CityWiseData
