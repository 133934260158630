import BarGraph from '../../Graphs/BarGraph'
import ScaleLoaderItem from '../../Ui-Elemnent/ScaleLoader'
import useAttendanceDetails from './data-fetch/fetchWardWiseAttendance'
const AttendanceChart = ({city, calendar, setPresentUserCount, setTotalUseCount }:{city:string, calendar:string, setPresentUserCount:any, setTotalUseCount:any}) => {
  const {attendanceData, loading} = useAttendanceDetails({city:city, calendar:calendar, setPresentUserCount:setPresentUserCount, setTotalUseCount:setTotalUseCount})
  const option = {
    maintainAspectRatio: false,
    
  }
 
  return (
    <div>
      {loading ? <ScaleLoaderItem loading={loading}/> : <BarGraph  chartData={attendanceData} option={option} /> }
    </div>
    
  )
}

export default AttendanceChart;