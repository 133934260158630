import React from 'react';
import './App.css';
import LandingPage from './components/LandingPage/LandingPage';
import Vendor from './components/Vendor/Vendor';
import { Auth } from './components/LoginComp/Auth';
import { Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes';
import Login from './components/LoginComp/Login';
import VenderOnboardingForm from './components/Vendor/VenderOnboardingForm';
import VendorDisplay from './components/Vendor/VendorDisplay';
import EditVendor from './components/Vendor/EditVendor';
import MainContent from './components/LandingPage/MainContent';
import ResetPassword from './components/LoginComp/ResetPwd';
import ForgetPassword from "./components/LoginComp/ForgotPassword"
import Insights from './components/cityLoop-Dashboard/Dashboard';
import ProDashboard from './components/Pro-Dashboard/Dashboard';
function App() {
  return (
    <div className="App">
    <Routes>
      
      <Route element={<PrivateRoutes />}>
        <Route path='/dashboard' element={<LandingPage />} > 
          <Route path='/dashboard' element={<MainContent />} />
          <Route path='/dashboard/vendor-reg' element={<VenderOnboardingForm />} />
          <Route path='/dashboard/vendors' element={<VendorDisplay />} />
          <Route path='/dashboard/vendor/:id' element={<Vendor />} />\
          <Route path='/dashboard/update-vendor/:id' element={<EditVendor />} /> 
         <Route path='/dashboard/insights' element={<Insights />} />
         <Route path='/dashboard/pro' element={<ProDashboard />} />
        </Route>
      </Route>
          <Route path="*" element={<Navigate to="/dashboard"  replace/>} />
      <Route path='/login' element= {<Login />} />
      <Route path='/user/reset-password' element={<ResetPassword />} />
      <Route path='/user/forget-password' element={<ForgetPassword />} />
    </Routes>
     {/* <Auth /> */}
    </div>
  );
}

export default App;
