
const BASEURL = process.env.REACT_APP_BASE_URL

const useVendorVerification =()=>{
  
//   const navigate = useNavigate();
      
    const verifyPan = async (p_body:any)=>{
       
        const res = await fetch(`${BASEURL}/v1/verify/pan?pan=${p_body}`, {
            mode: "cors",
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "GET",
          });
        //   console.log("p_body", p_body)
          const result = await res.json();

          return result
    }

    const verifyGstin = async (p_body:any)=>{
       
        const res = await fetch(`${BASEURL}/v1/verify/gstin?gstin=${p_body}`, {
            mode: "cors",
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "GET",
          });
        //   console.log("p_body", p_body)
          const result = await res.json();

          return result
    }

    const verifyIFSC = async (p_body:any)=>{
       
        const res = await fetch(`${BASEURL}/v1/verify/ifsc?Ifsc=${p_body}`, {
            mode: "cors",
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "GET",
          });
        //   console.log("p_body", p_body)
          const result = await res.json();

          return result
    }

    const verifyBankAccount = async (account:string, ifsc:string)=>{
       
        const res = await fetch(`${BASEURL}/v1/verify/bankaccount?AccountNo=${account}&Ifsc=${ifsc}`, {
            mode: "cors",
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "GET",
          });
        //   console.log("p_body", p_body)
          const result = await res.json();

          return result
    }
    
    return {verifyPan, verifyGstin, verifyIFSC, verifyBankAccount}
}

export default useVendorVerification;