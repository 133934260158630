import React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import useCustomerDetails from "./pro-datafetch/customer";
import BarGraph from "../cityLoop-Dashboard/Graphs/BarGraph";
import { PieChart } from "../UI-Helper-Components/Charts/Pie";
const CustomerContract = () => {
  const {
    customerData,
    materialWiseCustomerData,
    customerTypeData,
    verifiedCustomer,
  } = useCustomerDetails();
  const option = {
    maintainAspectRatio: false,
    responsive: true,
  };

  const data = {
    labels: ["Verified", "Non-verified"],
    datasets: [
      {
        label: "No. of customer",
        data: [...verifiedCustomer],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Box>
      <Paper sx={{ margin: 2, padding: 2 }}>
        <Typography variant="h6" sx={{ margin: 2 }}>
          Customer Stats
        </Typography>
        <Divider />
        <Grid>
          <BarGraph chartData={customerTypeData} option={option} />
        </Grid>
        <Divider />
        <Grid>
          <PieChart data={data} />
        </Grid>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Contract{" "}
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {customerData.totalCustomers}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Contract Value (MT){" "}
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {customerData.totalContracted?.toFixed(3)}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Fulfillments (MT){" "}
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {customerData.totalProcessed?.toFixed(3)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Remaining (MT){" "}
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  {customerData.totalRemaining?.toFixed(3)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Divider />
          <Typography variant="h6" sx={{ margin: 2 }}>
            Material Fulfilment Status
          </Typography>
          <Grid>
            <BarGraph chartData={materialWiseCustomerData} option={option} />
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default CustomerContract;
