import { Box, Paper, Grid, Typography, Button, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useVendor from "./vendor-data-fetch/vendor-data";
import { useNavigate } from "react-router-dom";
const Vendor = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const { getVendorInfo } = useVendor();
  const [vendor, setVendor] = useState<any>({});
  useEffect(() => {
    (async () => {
      const result = await getVendorInfo(params.id);
      setVendor(result)
      console.log(result);
    })();
  }, [params]);
  return (
    <Box sx={{ padding:4, margin:4, paddingLeft:4}}>
       <Box m={1}
 //margin
  display="flex"
  justifyContent="flex-end"
  alignItems="flex-end"
  >

      <Button sx={{marginRight:0}} variant='contained' color="warning" onClick={()=>{
      navigate(`/dashboard/update-vendor/${params.id}`)
      }}>Edit</Button>
      </Box>
      <Paper sx={{padding:4}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>Name: {vendor?.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Vendor Type: {vendor?.vendorType}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Legal Status: {vendor?.legalStatus}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Pan: {vendor?.pan || ""}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Gstin: {vendor?.gstin || ""}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Building Name: {vendor?.buildingName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Street: {vendor?.street}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Location: {vendor?.location}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>State: {vendor?.state}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Pin Code: {vendor?.pinCode}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Mobile No.: {vendor?.mobile}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Email: {vendor?.email}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>IFSC: {vendor?.ifsc}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Account No.: {vendor?.bankAccountNo}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Account Holder Name: {vendor?.accountHolderName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>UPI: {vendor?.upi}</Typography>
          </Grid>

        </Grid>
        <Box>
            <Link href="/dashboard">
                <Button>Back</Button>
            </Link>
            <Button>Edit</Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Vendor;
