
import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_PRO_URL;

const useInventoryDetails = () => {

  
  const [inventorydata, setInventoryData] = useState<any>({})
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/api/v1/dashboard/basecamp-dashboard`,
          {
            headers: {
              "Content-Type": "application/json",
              mode: "cors",
            },
          }
        );

        const result = await response.json();
        const data:any = result?.data;
        // console.log(data)
        let totalRecycled:number = 0;
        let totalAllocated:number = 0;
        let totalBalenced:number = 0;
        let inventoryDetails:any = {};
        
        for(let item of data?.materialWiseData){
          // console.log(item);
            totalRecycled = totalRecycled + item?.totalFulfillments;
            totalAllocated = totalAllocated + item?.totalContracted;
            totalBalenced = totalBalenced + item?.totalRemaining

        }
        inventoryDetails.totalRecycled = totalRecycled;
        inventoryDetails.totalAllocated = totalAllocated;
        inventoryDetails.totalBalenced = totalBalenced ;
        inventoryDetails.totalCustomers = data?.totalCustomers?.contracts;
        inventoryDetails.totalVendors = data?.totalVendors?.contracts;
        inventoryDetails.TotalPurchaseOrderCustomer = data?.TotalPurchaseOrderCustomer;
        inventoryDetails.TotalPurchaseOrderVendor = data?.TotalPurchaseOrderVendor;
        inventoryDetails.revenueStats = data?.RevenueStats;
        inventoryDetails.totalMaterialType = data?.TotalMaterialType;
        setInventoryData(inventoryDetails)
        if (!didCancel) {
          
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, []);

  return {  inventorydata,loading, error };
};

export default useInventoryDetails ;
