import { useNavigate } from "react-router-dom";

const BASEURL = process.env.REACT_APP_BASE_URL;

const useForgetPassword = () => {
  const navigate = useNavigate();

  const sendPasswordResetLink = async (pBody:string) => {
    const res = await fetch(`${BASEURL}/v1/user/forget-password`, {
      body: pBody,
      mode: "cors",
      headers: {
        //   Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    const result = await res.json();
    //   console.log("result", result)
    if (result.success) {
      navigate("/login");
    }
    return result;
  };

  return { sendPasswordResetLink };
};

export default useForgetPassword;
