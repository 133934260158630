import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_INSIGHTS_BASE_URL;
const useWWBinCleanUpDetails = ({city, date, setTotalTspCountInWard: setTotalTspCountInWard, setCleanTspCountInWard }:{city:string, date:string, setTotalTspCountInWard:any, setCleanTspCountInWard:any}) => {

  
  const [binCleanUpData, setbinCleanupData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [{
        label: "Bin Clean up",
        data: 0,
        borderColor: "#ADD8E6",
        backgroundColor: "#ADD8E6",
        }, ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/bincleanup/ward?city=${city}&to=${date}&from=${date}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result);
        
        const wards = Object.keys(result.data.binCleanUpData);
        const propertiesCovered = wards.map((date) => {
                return Number(result.data.binCleanUpData[date].propertiesCovered);
              });
              const totalPropertiesCount = wards.map((ward) => {
                return Number(result.data.binCleanUpData[ward].totalPropertiesInWard);
              });
              // console.log("propertiesCovered", propertiesCovered)
        if (!didCancel) {
            setTotalTspCountInWard(totalPropertiesCount.reduce((a,b)=> a+b));
          setCleanTspCountInWard(propertiesCovered.reduce((a,b)=>a+b))
          setbinCleanupData({
            labels: wards,
            datasets: [
              {
                label: "Bin Clean up",
                data: propertiesCovered,
                borderColor: "#ADD8E6",
                backgroundColor: "#ADD8E6",
                maxBarThickness: 50
                },
            ],
          });
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, date]);

  return {  binCleanUpData, loading, error };
};

export default useWWBinCleanUpDetails ;
