import {  Button, Grid, Paper, TextField, Typography } from '@mui/material'

import { Form, Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useForgetPassword from "./data-fetch/forgot-password";


const ForgotPassword = () => {
    const { sendPasswordResetLink } = useForgetPassword();

  const paperStyle = {
    padding:50,
    // marginTop:"200px",
    // height:"35vh",
    width:320,
    
    margin:"10% auto"
  }
  const textFieledStyle = {
    padding:2,
    margin:3
  }

 
  return (
    <div >
    {/* <img src="/wallpaper.jpg" alt="wallpaer" /> */}
   <Grid>
    <Paper elevation={10} style={paperStyle}>
       
       
        <Grid >
        {/* <Avatar><LockOpenIcon /></Avatar> */}

         <Typography padding={2} align="center" variant='h4'>Reset Password</Typography>
        </Grid>
          <Formik initialValues={{ email: "" }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const body = {
                      email: values.email,
                    };
                    const result = await sendPasswordResetLink(JSON.stringify(body));
                    if (!result.success) {
                      toast(result.error, { type: "error", theme: "colored" });
                    } else {
                      toast(result.message, { type: "success", theme: "colored" });
                    }
                  } catch (error:any) {
                    toast(error, { type: "error", theme: "colored" });
                  }

                  setSubmitting(false);
                }}
              >
            {(props:any)=>(
              <Form>
                   <Field as={TextField} id="outlined-basic" label="Email" name='email' variant="outlined" type='email' fullWidth required style={textFieledStyle} />
                  <Button style={{margin:4}} fullWidth type='submit' variant="contained" >Reset Password</Button>

              </Form>
            )}
          </Formik>
      
    </Paper>
    <ToastContainer />
   </Grid>
   </div>
  )
}

export default ForgotPassword