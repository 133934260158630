import HorizontalBarChart from '../../Graphs/HorizontalBarChart';
import useMaterialTransferDetails from './data-fetch/fetchMaterialTransferData';
import ScaleLoaderItem from '../../Ui-Elemnent/ScaleLoader';
const CityWiseMaterialTransfer = ({city, fromDate, toDate, setTotalAmountofWaste }:{city:string, fromDate:string, toDate:string ,setTotalAmountofWaste:any}) => {

  const {materialTransferData, loading} = useMaterialTransferDetails({city:city, fromDate:fromDate, toDate:toDate, setTotalAmountofWaste:setTotalAmountofWaste})


  
    return (
        <div className='w-full m-4 h-1/3 '>
          {loading ? <ScaleLoaderItem loading={loading}/> : <HorizontalBarChart  chartData={materialTransferData} /> }
        
        </div>
      )
}

export default CityWiseMaterialTransfer