import {  Button, Grid, Paper, TextField, Typography } from '@mui/material'

import { Form, Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useUpdatePassword from "./data-fetch/update-password";
import { useSearchParams } from "react-router-dom";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get("userId");
    const token = searchParams.get("token");
    const { updatePassword } = useUpdatePassword();
  const paperStyle = {
    padding:50,
    // marginTop:"200px",
    // height:"35vh",
    width:320,
    
    margin:"10% auto"
  }
  const textFieledStyle = {
    padding:2,
    margin:3
  }

 
  return (
    <div >
    {/* <img src="/wallpaper.jpg" alt="wallpaer" /> */}
   <Grid>
    <Paper elevation={10} style={paperStyle}>
       
       
        <Grid >
        {/* <Avatar><LockOpenIcon /></Avatar> */}

         <Typography padding={2} align="center" variant='h4'>Reset Password</Typography>
        </Grid>
          <Formik initialValues={{ password: "", confirmPassword: "" }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    if (values.password === values.confirmPassword) {
                      const body = {
                        id: userId,
                        token,
                        password: values.password,
                      };
                      const update = await updatePassword(JSON.stringify(body));
                      if (!update.success) {
                        // console.log(update.error)
                        toast(update.error, { type: "error", theme: "colored" });
                      } else {
                        toast(update.message, { type: "success", theme: "colored" });
                      }
                    } else {
                      toast("Password and Confirm Password does not matched", {
                        type: "error",
                        theme: "colored",
                      });
                    }
                  } catch (error:any) {
                    toast( error, { type: "error", theme: "colored" });
                  }

                  setSubmitting(false);
                }}>
            {(props:any)=>(
              <Form>
                   <Field as={TextField} id="outlined-basic" label="Password" name='password' variant="outlined" type={'password'} fullWidth required style={textFieledStyle} />
                  <Field as={TextField} id="outlined-basic" label="Confirm Password" name='confirmPassword' variant="outlined" type={'password'} fullWidth required style={textFieledStyle}/>
                  <Button style={{margin:4}} fullWidth type='submit' variant="contained" >Update Password</Button>

              </Form>
            )}
          </Formik>
      
    </Paper>
    <ToastContainer />
   </Grid>
   </div>
  )
}

export default ResetPassword