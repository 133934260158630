import React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import useVendorDetails from "./pro-datafetch/vendor";
import BarGraph from "../cityLoop-Dashboard/Graphs/BarGraph";
import { PieChart } from "../UI-Helper-Components/Charts/Pie";
const VendorContract = () => {
  const { vendorData,  materialWiseVendorData, vendorTypeData, verifiedVendor } = useVendorDetails();
  const option = {
    maintainAspectRatio: false,
    responsive: true,
    
  }

  const data = {
    labels: ["Verified", "Non-verified"],
    datasets: [
      {
        label: "No. of Vendor",
        data: [0,1],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  
  return (
    <Box>
      <Paper sx={{ margin: 2, padding: 2 }}>
        <Typography variant="h6" sx={{ margin: 2 }}>
          Vendor Stats
        </Typography>
        <Divider />
        <Grid>
          <BarGraph chartData={vendorTypeData} option={option}/>
          </Grid>
          <Divider />
          <Grid>
          <PieChart data={data} />
          </Grid>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Contract 
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                {vendorData?.totalVendor}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Contract Value (MT) 
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                {vendorData?.totalContracted?.toFixed(3)} 
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Recycled / Co-processed (MT) 
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                {vendorData?.totalProcessed?.toFixed(3)} 
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Paper sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                  Total Remaining (MT) 
                </Typography>
                <Typography variant="h6" sx={{ margin: 2 }}>
                {vendorData?.totalRemaining?.toFixed(3)} 
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box>
        <Divider />
        <Typography variant="h6" sx={{ margin: 2 }}>
         Material Procurement Status
        </Typography>
          <Grid>
          <BarGraph chartData={materialWiseVendorData} option={option}/>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default VendorContract;
