import  {useNavigate}  from "react-router-dom";
const BASEURL = process.env.REACT_APP_BASE_URL

const useUserLogin =()=>{

  const navigate = useNavigate();
      
    const login = async (p_body:any)=>{
      
        const res = await fetch(BASEURL + "/v1/user/login", {
            body: p_body,
            mode: "cors",
            headers: {
              // Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
          });
          const result = await res.json();
        //   console.log("result", result)
          if(result.success){
            // setUserInfo()
            const userData = {
              username : result.data.username,
              roleId: result.data.roleId,
              city: result.data.city,
              state: result.data.state,
              email: result.data.email
            }
     
            localStorage.setItem("userInfo", JSON.stringify(userData))
            localStorage.setItem("auth-token",JSON.stringify(result.data.token));
            navigate('/dashboard')
          }
          return result
    }

    
    return {login}
}

export default useUserLogin;