
import BarGraph from '../../Graphs/BarGraph'
import ScaleLoaderItem from '../../Ui-Elemnent/ScaleLoader'
import useWardWiseCollectionDetails from './data-fetch/fetchScannedCollection'
const ScannedCollectionChart = ({city, calendar, setScannedPropertiesInWard,setTotalPropertiesInWard }:{city:string, calendar:string, setScannedPropertiesInWard:any, setTotalPropertiesInWard:any}) => {
  const {collectionData, loading} = useWardWiseCollectionDetails({city:city, calendar:calendar, setScannedPropertiesInWard:setScannedPropertiesInWard, setTotalPropertiesInWard:setTotalPropertiesInWard})
  const option = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        
        beginAtZero: true,
      },
    },
  }
  return (
    <div>
      {loading? <ScaleLoaderItem loading={loading} /> :<BarGraph  chartData={collectionData} option={option}/>  }
    </div>
    
  )
}

export default ScannedCollectionChart