import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



export function PieChart({data}:{data:any}) {
    return (
        <div className='h-96 w-full'>
            <Pie data={data} />;
        </div>
      )

}
  