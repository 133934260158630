import BarGraph from '../../Graphs/BarGraph'
import ScaleLoaderItem from '../../Ui-Elemnent/ScaleLoader'

import useSagrigationDetails from './data-fetch/fetchSegrigatedCollection'
const SegregationVsCollectionChart = ({city, calendar, setSegrigatedCollectionInWard, setScannedPropertiesInWard}:{city:string, calendar:string, setSegrigatedCollectionInWard:any, setScannedPropertiesInWard:any}) => {
  const { sagrigationData, loading} = useSagrigationDetails({city:city, calendar:calendar, setSegrigatedCollectionInWard:setSegrigatedCollectionInWard, setScannedPropertiesInWard:setScannedPropertiesInWard})
  const option = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        
        beginAtZero: true,
      },
    },
  }
  
  return (
    <div>
      {loading ? <ScaleLoaderItem loading={loading} /> : <BarGraph  chartData={sagrigationData} option={option} /> }
    </div>
    
  )
}

export default SegregationVsCollectionChart