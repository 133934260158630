import React, { useEffect, useState } from 'react'
import DateRangeSelector from '../../Ui-Elemnent/DateRange'
import CalenderSelctor from '../../Ui-Elemnent/Calender'
import CityBinCleanUp from './CityBinCleanUp'
import WardWiseBinCleanUp from './WardWiseBInCleanUp'
import format from 'date-fns/format'
// const TabName = ["Ward Wise Collection", "Ward Wise Collection Vs Segregation", "Ward Wise Attendance" ]

const BinCleanupData = ({city}:{city:string}) => {
  const [selectedTab, setSelectedTab] =  useState("Bin Clean-Up");
  const [calendar, setCalendar] = useState<any>((new Date()));
  const [selectdDate, setSelectedDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [showExtraInfo, setShowExtraInfo] = useState("");
  ///////////
  const [dayDiff, setDaydiff] =useState<any>()
  const today = new Date()
  const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [range, setRange] = useState<any[]>([
    {
      endDate: new Date(),
      startDate: today.setDate(today.getDate()-6),
      key: 'selection', 
    }
  ])
  ///////
   const [totalTspCount, setTotalTspCount] = useState<number>(0);
   const [cleanTspCount, setCleanTspCount] = useState<number>(0);
   const [totalTspCountInWard, setTotalTspCountInWard] = useState<number>(0);
   const [cleanTspCountInWard, setCleanTspCountInWard] = useState<number>(0);


  ///////
  useEffect(()=>{

    setToDate(format(range[0].endDate, "yyyy-MM-dd"))
    setFormDate(format(range[0].startDate, "yyyy-MM-dd"))
    setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
  
  },[range])
  ///////

  useEffect(()=>{
    setSelectedDate(format(calendar, "yyyy-MM-dd"))
  },[calendar])

  const TabName = [{tabName:"Bin Clean-Up", numerator: cleanTspCount/(dayDiff +1), denominator: totalTspCount, extraInfo:"Shows the number of bins where the collection happened over the selected timeline. When selected timeline is more than a day, it will be averaged."}, {tabName:"Ward Wise Bin Clean-Up", numerator: cleanTspCountInWard, denominator: totalTspCount, extraInfo:"Shows the number of bin cleaned up for a particular ward on the selected date."} ]

  const HandleOnMouseEnter = (tabName:string)=>{
    setShowExtraInfo(tabName)
  }
  const HandleOnMouseLeave = ()=>{
    setShowExtraInfo("")
  }


  return (
    <div className='w-full ml-2 mt-8 h-1/3'>
     <div className='flex justify-end mr-4'>
        {selectedTab === "Ward Wise Bin Clean-Up" ? <CalenderSelctor calendar={calendar} setCalendar={setCalendar}/> : <DateRangeSelector range={range} setRange={setRange}  />}

      </div>
      <div className='border-2 border-t-0 bg-white'>
      <div className='flex justify-between'>
        {
          TabName.map((item, index)=>{
            return(
              <div key={index} className={`h-12  border w-1/2 cursor-pointer hover:bg-lightBlue py-2 border border-black  ${selectedTab === item.tabName ? "bg-lightBlue text-recity": "bg-recity text-white"}`} onClick={()=>setSelectedTab(item.tabName)} onMouseEnter={()=>HandleOnMouseEnter(item.tabName)} onMouseLeave={HandleOnMouseLeave}> 
              <h1 className='text-2xl font-medium text-center'>{item.tabName}{` (${!Number.isNaN(item.numerator) ? Math.floor(item.numerator).toString(): 0}/${Math.floor(item.denominator).toString()})`} </h1>
              <div className={`relative text-sm rounded-lg bg-black text-white items-end mx-auto mt-2 py-4 ${showExtraInfo !== item.tabName ? "hidden": ""}`}>{item.extraInfo}</div>
          </div>
            )
          })
        }
      </div>
      <div>
      {
        selectedTab === "Ward Wise Bin Clean-Up" ?  <WardWiseBinCleanUp city={city} calendar={selectdDate} setCleanTspCountInWard={setCleanTspCountInWard} setTotalTspCountInWard={setTotalTspCountInWard}/>  : <CityBinCleanUp city={city} fromDate={fromDate} toDate={toDate} setTotalTspCount={setTotalTspCount} setCleanTspCount={setCleanTspCount}  />
      }    
    </div>
      <div className='hidden'>
       <WardWiseBinCleanUp city={city} calendar={selectdDate} setCleanTspCountInWard={setCleanTspCountInWard} setTotalTspCountInWard={setTotalTspCountInWard}/>  
        <CityBinCleanUp city={city} fromDate={fromDate} toDate={toDate} setTotalTspCount={setTotalTspCount} setCleanTspCount={setCleanTspCount}  />   
    </div>

    </div>
    </div>
  )
}

export default BinCleanupData
