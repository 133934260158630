
import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_PRO_URL;

const useVendorDetails = () => {
  const [materialWiseVendorData, setMaterialWiseVendorData] = useState<{labels: any[]; datasets: any[]}>({
    labels:[],
    datasets: [ ],
  });
  const [vendorTypeData, setVendorTypeData] = useState<{labels: any[]; datasets: any[]}>({
    labels:[],
    datasets: [ ],
  });

  const [verifiedVendor, setVerifiedVendor] = useState<any[]>([])
  
  const [vendorData, setVendorData] = useState<any>({})
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/api/v1/dashboard/basecamp-vendor`,
          {
            headers: {
              "Content-Type": "application/json",
              mode: "cors",
            },
          }
        );

        const result = await response.json();
        const data:any = result?.data;
        console.log("data", data)
        let totalContracted:number = 0;
        let totalProcessed:number = 0;
        let totalRemaining:number = 0;
        let vendoeDetails:any = {};

        for(let item of data?.materialWiseData){
            totalContracted = totalContracted + item?.totalContracted;
            totalProcessed = totalProcessed + item?.totalProcessed;
            totalRemaining = totalRemaining + item?.totalRemaining

        }
        vendoeDetails.totalContracted = totalContracted;
        vendoeDetails.totalProcessed = totalProcessed;
        vendoeDetails.totalRemaining = totalRemaining ;
        vendoeDetails.totalVendor = data?.totalVendors?.contracts
        
        if (!didCancel) {
          setVendorData(vendoeDetails);
          setMaterialWiseVendorData({
            labels: data?.materialWiseData?.map((obj:any) => obj?.materialType),
            datasets: [
              
              {
                label:  "Total Contracted",
                data: data?.materialWiseData?.map((obj:any) => obj?.totalContracted),//,
               borderColor: "#23418D",
               backgroundColor: "#23418D",
               maxBarThickness: 50
             },
             {
                label: "Total Processed",
                data: data?.materialWiseData?.map((obj:any) => obj?.totalProcessed),
                borderColor: "#FFE45C", 
                backgroundColor: "#FFE45C",
                maxBarThickness: 50
             },
             {
              label:  "Total Remaining",
              data: data?.materialWiseData?.map((obj:any) => obj.totalRemaining),
             borderColor: "#ADD8E6",
             backgroundColor: "#4CC6DE",
             maxBarThickness: 50
           },
            ],
          });
          
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(()=>{
    let didCancel = false;
    setError(null);
    (async () =>{
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/api/v1/dashboard/type-vendor`,
          {
            headers: {
              "Content-Type": "application/json",
              mode: "cors",
            },
          }
        );

        const result = await response.json();
        const data:any = result?.response;
        console.log("data", data);
        const verificationData = [data?.NumberOfVerifiedVendor, data?.NumberOfUnverifiedVendor]
        setVerifiedVendor(verificationData);
        const VendorDataArr = [
          {type:"Converter",
            countPercentage: data?.percentageOfConvertorCount
          },
          {type:"Co-Processor",
          countPercentage: data?.percentageOfCoprocessorCount
        },
        {type:"Main Collection Partner",
        countPercentage: data?.percentageOfMaincollectionPartner
      },
      {type:"Recycler",
      countPercentage: data?.percentageOfRecyclerCount
    },
    {type:"Sorting Partner",
      countPercentage: data?.percentageOfSortingPartnerCount
    },
    {type:"Sub Collection partner",
      countPercentage: data?.percentageOfSubcollectionPartner
    },
    {type:"Washing partner",
      countPercentage: data?.percentageOfWashingPartnerCount
    },
        ];

        // console.log("customerDataArr", customerDataArr)
       
        if (!didCancel) {
          setVendorTypeData({
            labels: VendorDataArr?.map((obj:any) => obj?.type),
            datasets: [
              
              {
                label:  "",
                data: VendorDataArr?.map((obj:any) => obj?.countPercentage),
               borderColor: "#23418D",
               backgroundColor: [ "blue", "pink", "yellow", "green"],
               maxBarThickness: 50
             },
            
            ],
          });
          
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  },[])

  return {  vendorData, materialWiseVendorData, vendorTypeData, verifiedVendor, loading,  error };
};

export default useVendorDetails ;
