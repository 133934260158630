import LineChart from '../../Graphs/LineChart'
import useBinCleanUpDetails from './data-fetch/fetch-binCleanUpData'
import ScaleLoaderItem from '../../Ui-Elemnent/ScaleLoader'
const CityBinCleanUp = ({city, fromDate, toDate,  setTotalTspCount, setCleanTspCount}:{city:string, fromDate:string, toDate:string, setTotalTspCount:any, setCleanTspCount:any}) => {
  const option = {
    maintainAspectRatio: false
  }
 const {binCleanUpData, loading} = useBinCleanUpDetails({city:city, fromDate:fromDate, toDate:toDate, setTotalTspCount:setTotalTspCount, setCleanTspCount:setCleanTspCount })
  return (
    <div className=''>
    
    <div>
    {loading ? <ScaleLoaderItem loading={loading}/> : <LineChart chartData={binCleanUpData} option={option}  /> }
        
    </div>
    </div>
  )
}

export default CityBinCleanUp