import React, { useState } from 'react'
import { BsChevronDown } from "react-icons/bs";
// const list = ["Asd","asd","zcx"]
export const DropDown = ({list, selected, setSelected}:{list:any[], selected:string,setSelected:any}) => {
  // const [selected, setSelected] = useState("Select");
  const [open, setOpen] = useState(false)
  return (
    <div>
      <div className='w-48 h-8 relative border border-gray-400 rounded-md bg-gray-300 px-2 flex justify-between'>
       <div className='my-auto'> 
       {selected}
       </div>
       <div className='my-auto cursor-pointer border-l border-indigo-600 pl-2' onClick={()=> setOpen(!open)}>
        <BsChevronDown className={`${open? "rotate-180":""}`}/>
       </div>
      </div>
      <div className='w-48 absolute bg-white z-30'>
      <ul className={`overflow-y-auto  border rounded ${open?'max-h-60':'hidden'}`}>
        {
          list.map((item, index)=>{
            return(

              <li key={index} className={`p-2 text-sm hover:bg-lightBlue hover:text-recity text-start relative z-5${selected === item ? ' bg-lightBlue':"" }`} onClick={()=>{
                setSelected(item)
                setOpen(!open)
              }}>{item}</li>
            )
          })
        }
      </ul>
    </div>
    </div>
  )
}

