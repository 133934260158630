import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_INSIGHTS_BASE_URL;
const useBinCleanUpDetails = ({city, fromDate, toDate, setTotalTspCount, setCleanTspCount }:{city:string, fromDate:string, toDate:string, setTotalTspCount:any, setCleanTspCount:any}) => {

  
  const [binCleanUpData, setbinCleanupData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [{
        label: "Bin Clean up",
        data: 0,
        borderColor: "#ADD8E6",
        backgroundColor: "#ADD8E6",
        }, ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/bincleanup?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result);
        
        const dates = Object.keys(result.data.binCleanUpData);
        dates.shift();
        const propertiesCovered = dates.map((date) => {
                return Number(result.data.binCleanUpData[date].propertiesCovered);
              });
              
              // console.log("propertiesCovered", propertiesCovered)
        if (!didCancel) {
          // console.log("total tsp", result.data.binCleanUpData.totalTsp)
          setTotalTspCount(result.data.binCleanUpData.totalTsp);
          setCleanTspCount(propertiesCovered.reduce((a,b)=>a+b))
          setbinCleanupData({
            labels: dates,
            datasets: [
              {
                label: "Bin Clean up",
                data: propertiesCovered,
                borderColor: "#ADD8E6",
                backgroundColor: "#ADD8E6",
                },
            ],
          });
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city,toDate, fromDate ]);

  return {  binCleanUpData, loading, error };
};

export default useBinCleanUpDetails ;
