import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Field } from 'formik';

export default function BasicSelect({title,name, options, getter, setter, required, multiple}:{title:string, name:string, options:string[], getter:any, setter:any, required:boolean, multiple:boolean}) {
 

  const handleChange = (event: SelectChangeEvent) => {
    setter(event.target.value as string);
  };

  return (
    <Box sx={{ marginLeft:2, marginBottom:2 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        {/* <Field */}
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={getter}
          label={title}
          onChange={handleChange}
          required={required}
          name={name}
          multiple={multiple}
        >
          {options.map((value, index)=>{
            return(
              <MenuItem key={value} value={value}>{value}</MenuItem>
            )
          })}
        {/* </Field> */}
        </Select>
      </FormControl>
    </Box>
  );
}