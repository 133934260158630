import LineChart from "../../Graphs/LineChart";
import ScaleLoaderItem from "../../Ui-Elemnent/ScaleLoader";
import useCollectionDetails from "./data-fetch/fetchScannedCollection";

const ScannedCollectionChart = ({city, fromDate, toDate, setScannedProperties, setTotalProperties }:{city:string, fromDate:string, toDate:string, setScannedProperties:any, setTotalProperties:any}) => {
  const option = {
    maintainAspectRatio: false,
    scales: {
      y: {
          beginAtZero: true,
          max: 100
      }
  }
  }
  const  {collectionData, loading} = useCollectionDetails({city:city, fromDate:fromDate, toDate:toDate, setScannedProperties:setScannedProperties, setTotalProperties:setTotalProperties});
  return (

  <div>
  {loading ? <ScaleLoaderItem loading={loading}/>: <LineChart chartData={collectionData} option={option}/>  }
  </div>
  )
};

export default ScannedCollectionChart;
