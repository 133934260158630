import { Button, Grid, Paper, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import Select from "../UI-Helper-Components/Select";
import useVendorVerification from "./vendor-data-fetch/vendorVerification";
import useVendor from "./vendor-data-fetch/vendor-data";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const VenderOnboardingForm = () => {
  const { verifyPan, verifyGstin, verifyIFSC, verifyBankAccount } =
    useVendorVerification();
  const { addVendor } = useVendor();
  const paperStyle = {
    padding: 50,
    margin: "auto",
  };
  const textFieledStyle = {
    padding: 2,
    marginLeft: "10px",
  };
  const [vendorType, setVendeorType] = useState("");
  const [selectedDocs, setSelectedDocs] = useState("");
  const [vendorRole, setVendorRole] = useState<string[]>([]);
  const [platforms, setplatforms] = useState<string[]>([]);
  const [legalStatus, setLegalStatus] = useState("");
  const [vendorData, setVendorData] = useState<any>({});
  const [ifscData, setIfscData] = useState<any>({});
  const [verified, setVerified] = useState<boolean>(false)

  // to set manual vaalues of fiels
  const [accountNo, setAccountNo] = useState<string>("");
  const [name, setName] = useState("");
  const [stJuridictionCode, setStJuridictionCode] = useState("");
  const [lgnb, setlgnb] = useState("");
  const [nob, setNob] = useState("");
  const [regdt, setregdt] = useState("");
  const [bldName, setBldName] = useState("");
  const [strt, setStrt] = useState("");
  const [loc, setLoc] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincd, setPincd] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankName, setBankName] = useState("");
  

  // select Fields
  const organizationTypeVendor = [
    "DWCC",
    "NGO",
    "Bulk Generator",
    "Scrap Shops",
    " Government Organizations",
    "MSW (Municipal Solid Waste)",
  ];
  const individualTypeVendor = [
    "Aggregator",
    "Recycler",
    "Rag Pickers",
    " Waste Collector",
    "Citizen",
  ];
  const vendorTypeOptions = [
    ...organizationTypeVendor,
    ...individualTypeVendor,
  ];
  const OrganiZationTypedocumentsOptions = ["GSTIN", "PAN"];
  const IndividualTypedocumentsOptions = ["PAN", "AADHAR"];

  const vendorRoleOptions = [
    "Organization",
    "Individual Vendor",
    "Main - Collection Partner",
    "Sub- Collection Partner",
    "Recycler",
    "Co-processor",
    "Washing Partner",
    "Sorting Partner ",
    "Convertor",
  ];
  const platformOptions = ["PRO", "FMS"]
  const LegalStatusOptions = [
    "Public Limited Company",
    "Private Limited Company",
    "Partnership Ferm",
    "Limeted Liability Partnership",
    "Other",
  ];

  let documentMinLength = 10;
  let documentMaxLength = 10;
  useEffect(() => {
   
    documentMinLength =
      selectedDocs === "PAN" ? 10 : selectedDocs === "AADHAR" ? 12 : 15;

    documentMaxLength =
      selectedDocs === "PAN" ? 10 : selectedDocs === "AADHAR" ? 12 : 15;
      console.log("selected docs length", documentMinLength)
  }, [selectedDocs]);

  const handleDocumentIdChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    let documentId = value.toUpperCase();
    const panRegex: any = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const gstRegex: any =
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    const isValidPAn = panRegex.test(documentId);
    const isValidGstin = gstRegex.test(documentId);
    if (isValidPAn) {
      const panDetails = await verifyPan(documentId);
      setVendorData(panDetails);
      setVerified(true);
      console.log("vendorData", vendorData);
    }
    if (isValidGstin) {
      // console.log("gstin")
      const gstDatails = await verifyGstin(documentId);
      setVendorData(gstDatails);
      setVerified(true);
      console.log("vendorData", vendorData);
    }
  };
  const handleIfscCodeChange = async (e: any) => {
    const value: string = e.target.value;
    const ifsc = value.toUpperCase();
    console.log(ifsc);
    let ifscRegex = /[A-Z]{4}0[A-Z0-9]{6}/;
    const isValidIfsc = ifscRegex.test(ifsc);
    console.log("ifsc", isValidIfsc);
    if (isValidIfsc) {
      console.log("ifsc", ifsc);
      const ifscDetails = await verifyIFSC(ifsc);
      setIfscData(ifscDetails);
      setBranchName(ifscData?.data?.BRANCH);
      console.log(ifscData);
    }
  };
  const handleAccountNoChange = (e: any) => {
    let account_No = e.target.value;
    setAccountNo(account_No);
    console.log(accountNo);
  };
  const handleVerifyAccount = () => {
    const result = verifyBankAccount(accountNo, ifscData?.data?.IFSC);
  };
  const handleSubmit = async (event:any)=>{
    event.preventDefault();
    const vendorDetails: any = {
      vendorType : event.target.vendorType.value,
      vendorRole : vendorRole,
      legalStatus : legalStatus,
      documentName : selectedDocs,
      platform: platforms,
      documentIdNo : event.target.documentIdNo.value,
      name : event.target.name.value,
      email : event.target.email.value,
      mobile : event.target.mobile.value,
      age : event.target.age.value,
      gstin : selectedDocs === "GSTIN" ? event.target.documentIdNo.value : "",
      pan : selectedDocs === "PAN" ? event.target.documentIdNo.value : "",
      aadhar : selectedDocs === "AADHAR" ? event.target.documentIdNo.value : "",
      stateCode : event.target.stateCode.value,
      businessName : event.target.businessName.value,
      natureOfBusiness : event.target.natureOfBusiness.value,
      dateOfRegistration : event.target.dateOfRegistration.value,
      buildingName : event.target.buildingName.value,
      street : event.target.street.value,
      location : event.target.location.value,
      doorNumber : event.target.doorNumber.value,
      district : event.target.district.value,
      city : event.target.city.value,
      state : event.target.state.value,
      pinCode : event.target.pinCode.value,
      longitude : event.target.longitude.value,
      lattitude : event.target.lattitude.value,
      ifsc : event.target.ifsc.value,
      bankName : event.target.bankName.value,
      branch : event.target.branch.value,
      bankAccountNo  : event.target.accountNo.value,
      accountHolderName : event.target.accountHolderName.value,
      upi : event.target.upiID.value,
      verified : verified
    };
    console.log("vendorDetails", vendorDetails);
    const vendor = await addVendor(JSON.stringify(vendorDetails));
    if(!vendor?.success){
      toast(vendor.message, {type:"error", theme:"colored"});
    }else{
      toast(vendor.message, {type:"success", theme:"colored"});
      //  setLogedIn(loginUser.data)
     }
  }

  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <form onSubmit={(e)=>{
          handleSubmit(e)
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                  <Select
                    // as={Select}
                    name="vendorType"
                    title={"Select Vendor Type"}
                    options={vendorTypeOptions}
                    getter={vendorType}
                    setter={setVendeorType}
                    required
                    multiple={false}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    //as={Select}
                    name="vendorRole"
                    title={"Select Vendor Role"}
                    options={vendorRoleOptions}
                    getter={vendorRole}
                    setter={setVendorRole}
                    required
                    multiple
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    // as={Select}
                    name="legalStatus"
                    title={"Select Legal Status"}
                    options={
                      organizationTypeVendor.includes(vendorType)
                        ? LegalStatusOptions
                        : ["sole proprietorship"]
                    }
                    getter={legalStatus}
                    setter={setLegalStatus}
                    required={false}
                    multiple={false}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    //as={Select}
                    name="selectPlatform"
                    title={"Select Platform"}
                    options={platformOptions}
                    getter={platforms}
                    setter={setplatforms}
                    required
                    multiple
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    
                    name="documentName"
                    title={"Select Valid ID"}
                    options={
                      organizationTypeVendor.includes(vendorType)
                        ? OrganiZationTypedocumentsOptions
                        : IndividualTypedocumentsOptions
                    }
                    getter={selectedDocs}
                    setter={setSelectedDocs}
                    required={false}
                    multiple={false}
                    // fullWidth
                  />
                </Grid> 
                <Grid item xs={12} sm={4}>
                  <TextField
                    // as={TextField}
                    onChange={(event:any) => handleDocumentIdChange(event)}
                    id="outlined-basic"
                    label="Documnet ID No."
                    name="documentIdNo"
                    variant="outlined"
                    type={"text"}
                    // value={""}
                    fullWidth
                    required
                    style={textFieledStyle}
                    // inputProps={{ minLength: documentMinLength, maxLength:documentMaxLength, style: { textTransform: "uppercase" } }}
                  />
                </Grid> 
            {/* <Grid item xs={12} sm={4}>
              <TextField
                
                // onChange={(event:any) => handleTextFieldChange(event)}
                id="outlined-basic"
                label="Confirm Documnet ID No."
                name="documentIdNo"
                variant="outlined"
                type={"text"}
                defaultValue={
                  vendorData?.data?.data?.pan ||
                  vendorData?.data?.data?.gstin ||
                  ""
                }
                fullWidth
                required
                style={textFieledStyle}
                inputProps={{
                  minLength: documentMinLength,
                  maxLength: documentMaxLength,
                  style: { textTransform: "uppercase" },
                }}
              />
            </Grid> */}
            
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Full Name"
                name="name"
                variant="outlined"
                type="text"
                // InputLabelProps={{ shrink: true }}
                value={
                  vendorData?.data?.data?.full_name ||
                  vendorData?.data?.data?.tradeNam ||
                  name
                }
                fullWidth
                required
                style={textFieledStyle}
                onChange={(e)=>{setName(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Email"
                name="email"
                variant="outlined"
                type="email"
                fullWidth
                required
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Mobile No."
                name="mobile"
                variant="outlined"
                type="number"
                fullWidth
                required
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Age"
                name="age"
                variant="outlined"
                type={"text"}
                fullWidth
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Gender"
                name="gender"
                variant="outlined"
                type={"text"}
                fullWidth
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="State Juridiction Code"
                name="stateCode"
                variant="outlined"
                type="text"
                fullWidth
                value={vendorData?.data?.data?.stjCd || stJuridictionCode}
                style={textFieledStyle}
                onChange={(e)=>{setStJuridictionCode(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Legal name of Business"
                name="businessName"
                variant="outlined"
                type={"text"}
                fullWidth
                value={vendorData?.data?.data?.lgnm || lgnb}
                style={textFieledStyle}
                onChange={(e)=>{setlgnb(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Nature of Business"
                name="natureOfBusiness"
                variant="outlined"
                type={"text"}
                fullWidth
                value={vendorData?.data?.data?.nba?.[0] || nob }
                style={textFieledStyle}
                onChange={(e)=>{setNob(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Date Of Registration"
                name="dateOfRegistration"
                variant="outlined"
                type={"text"}
                fullWidth
                value={vendorData?.data?.data?.rgdt || regdt}
                style={textFieledStyle}
                onChange={(e)=>{setregdt(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Building Name"
                name="buildingName"
                variant="outlined"
                type="text"
                fullWidth
                value={vendorData?.data?.data?.pradr?.addr?.bnm || bldName}
                style={textFieledStyle}
                onChange={(e)=>{setBldName(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Street"
                name="street"
                variant="outlined"
                type={"text"}
                fullWidth
                value={vendorData?.data?.data?.pradr?.addr?.st || strt}
                style={textFieledStyle}
                onChange={(e)=>{setStrt(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Location"
                name="location"
                variant="outlined"
                type={"text"}
                fullWidth
                value={vendorData?.data?.data?.pradr?.addr?.loc || loc}
                style={textFieledStyle}
                onChange={(e)=>{setLoc(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Door Number"
                name="doorNumber"
                variant="outlined"
                type={"text"}
                fullWidth
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="District"
                name="district"
                variant="outlined"
                type="text"
                fullWidth
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="City"
                name="city"
                variant="outlined"
                type={"text"}
                fullWidth
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="State"
                name="state"
                variant="outlined"
                type={"text"}
                fullWidth
                value={vendorData?.data?.data?.pradr?.addr?.stcd || state}
                style={textFieledStyle}
                onChange={(e)=>{setState(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Pin Code"
                name="pinCode"
                variant="outlined"
                type={"text"}
                fullWidth
                value={vendorData?.data?.data?.pradr?.addr?.pncd || pincd}
                style={textFieledStyle}
                onChange={(e)=>{setPincd(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Longitude"
                name="longitude"
                variant="outlined"
                type={"text"}
                fullWidth
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Lattitude"
                name="lattitude"
                variant="outlined"
                type={"text"}
                fullWidth
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="IFSC Code"
                name="ifsc"
                variant="outlined"
                type={"text"}
                fullWidth
                inputProps={{
                  minLength: 11,
                  maxLength: 11,
                  style: { textTransform: "uppercase" },
                }}
                onChange={(e: any) => {
                  handleIfscCodeChange(e);
                }}
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Bank Name"
                name="bankName"
                variant="outlined"
                type="text"
                fullWidth
                value={ifscData?.data?.BANK || bankName}
                style={textFieledStyle}
                onChange={(e)=>{setBankName(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Branch"
                name="branch"
                variant="outlined"
                type={"text"}
                fullWidth
                required
                value={ifscData?.data?.BRANCH || branchName}
                style={textFieledStyle}
                onChange={(e)=>{setBranchName(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Account No."
                name="accountNo"
                variant="outlined"
                type="text"
                fullWidth
                required
                style={textFieledStyle}
                onChange={(e: any) => {
                  handleAccountNoChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                style={{ marginLeft: 15 }}
                variant="contained"
                onClick={handleVerifyAccount}
              >
                Verify Account details
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                
                id="outlined-basic"
                label="Account Holder Name"
                name="accountHolderName"
                variant="outlined"
                type={"text"}
                fullWidth
                required
                style={textFieledStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="outlined-basic"
                label="UPI ID"
                name="upiID"
                variant="outlined"
                type={"text"}
                fullWidth
                
                style={textFieledStyle}
              />
            </Grid>
          </Grid>
          <Button
            style={{ margin: 4 }}
            fullWidth
            type="submit"
            variant="contained"
          >
            Save Details
          </Button>
        </form>
      </Paper>
      <ToastContainer />
    </Grid>
  );
};

export default VenderOnboardingForm;
