import { useState, useEffect, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import format from 'date-fns/format'
import { Box } from '@mui/system';



export default function DateRangeSelector({range, setRange}:{range:any,setRange:any}) {
    const today = new Date();
  const refOne = useRef<any>(null)
  const [open, setOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any[]>([
    {
      endDate: new Date(),
      startDate: today.setDate(today.getDate()-6),
      key: 'selection', 
    }
  ])
  useEffect(()=>{
    document.addEventListener("keydown", hideOnEscKey, true);
    document.addEventListener("click", hideOnClickOutside, true)
  },[])
  const hideOnEscKey =(e:any)=>{
    if(e.key === "Escape"){
      setOpen(false)
    }
  }

  const hideOnClickOutside =(e:any)=>{
    if(refOne.current && !refOne.current.contains(e.target)){
      setOpen(false)
    }
    
  }

  const handleApply = ()=>{
    setRange(dateRange)
    setOpen(false)
  }

  const handleReset = ()=>{
  setDateRange(  [{
    endDate: new Date(),
    startDate: today.setDate(today.getDate()),
    key: 'selection', 
  }])
  }
  return (
    <div >
       <Box className='border rounded-md border-indigo-800 min-w-min  px-3 py-4 space-x-2  cursor-pointer relative' onClick={()=>setOpen(!open)}>
         {`${format(range[0].startDate, "yyyy-MM-dd")} to ${format(range[0].endDate, "yyyy-MM-dd")}`}
         {/* {`${format(range[0].startDate,"YYYY-mm-DD")}`} */}
      
     </Box>
      {open ? (
        <>
          <div 
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                
                <div className='border rounded-md border-indigo-800 min-w-min  px-3 py-3 space-x-2  cursor-pointer relative' onClick={()=>setOpen(!open)}>
                {`${format(range[0].startDate, "yyyy-MM-dd")} to ${format(range[0].endDate, "yyyy-MM-dd")}`}
               
            </div>
               
                {/*body*/}
                <div className="relative p-6 pt-2 flex-auto">
                <DateRangePicker  onChange={item => setDateRange([item.selection])} 
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                      months={2}
                      direction={'horizontal'}
                      maxDate={new Date()}
         />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-lightBlue text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={ handleReset}
                  >
                    Reset
                  </button>
                  <button
                    className="bg-recity text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={ handleApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}