import BarGraph from '../../Graphs/BarGraph'
import useWWBinCleanUpDetails from './data-fetch/fetchWardWiseBinCleanUPData';
import ScaleLoaderItem from '../../Ui-Elemnent/ScaleLoader';
const WardWiseBinCleanUp = ({city, calendar,setCleanTspCountInWard, setTotalTspCountInWard }:{city:string, calendar:string, setCleanTspCountInWard:any, setTotalTspCountInWard:any}) => {
  const {binCleanUpData, loading} = useWWBinCleanUpDetails({city:city, date:calendar, setTotalTspCountInWard:setTotalTspCountInWard, setCleanTspCountInWard:setCleanTspCountInWard })
  const option = {
    maintainAspectRatio: false,
    
  }
  
  return (
    <div>
   {loading ? <ScaleLoaderItem loading={loading} /> : <BarGraph  chartData={binCleanUpData} option={option}/>  } 
    </div>
  )
}

export default WardWiseBinCleanUp