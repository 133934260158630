import { useEffect, useState } from "react";
import  {useNavigate}  from "react-router-dom";
const BASEURL = process.env.REACT_APP_BASE_URL
const token:string = localStorage.getItem("auth-token") || "";
const useVendor =()=>{
  const [vendorsDetails, setVendorDetails] = useState<any[]>([]);
  const navigate = useNavigate();
     
    const addVendor = async (p_body:any)=>{
      
        const res = await fetch(BASEURL + "/v1/vendor", {
            body: p_body,
            mode: "cors",
            headers: {
              // Authorization: `${token}`,
              "x-user-token": `${JSON.parse(token)}`,
              "Content-Type": "application/json",
              "x-api-key": "mKJ6dHuT8dNBvKX6",
            },
            method: "POST",
          });
          const result = await res.json();
          if(result.success){
            navigate("/dashboard/vendors")
          }
          return result
    }

    useEffect(()=>{
      (async()=>{
        const res = await fetch(BASEURL + "/v1/vendor", {
          mode: "cors",
          headers: {
          //   Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "GET",
        });
        const result = await res.json();
        setVendorDetails(result?.data)
      })()
    },[]);

      const getVendorInfo = async(id:string)=>{
        const res = await fetch(`${BASEURL}/v1/vendor/${id}`, {
          mode: "cors",
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "mKJ6dHuT8dNBvKX6",
            "Content-Type": "application/json",
          },
          method: "GET",
        });
        const result = await res.json();
        
        return result?.data;
      }
   
    
    return {addVendor, vendorsDetails, getVendorInfo}
}

export default useVendor;