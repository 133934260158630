
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import Invetory from './Inventory'
import VendorContract from './VendorContract'
import CustomerContract from './CustomerContract'
import MaterialSelection from './MaterialSelection'
const ProDashboard = () => {
  return (
   <Box>
    <Box sx={{margin:2, padding:2}}  color={"white"} bgcolor={"#23418D"}>
        <Typography variant='h5' sx={{fontWeight: 'medium', textTransform: 'uppercase'}}>
            pro dashboard
        </Typography>
    </Box>
        {/* <MaterialSelection /> */}
        <Invetory />
        <Grid container spacing={1}>
        <Grid item xs={6} sm={6}>
            <VendorContract />

            </Grid>
            <Grid item xs={6} sm={6}>
             <CustomerContract />

            </Grid>
            
        </Grid>
        
   </Box>
  )
}

export default ProDashboard