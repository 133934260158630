
import "react-datepicker/dist/react-datepicker.css";
import BinCleanupData from "./BinCleanUp/BinCleanup";
import CityWiseData from './CityWiseData/CityWiseData';
import MaterialTransferData from "./MaterialTransfer/MaterialTransfer";

import WardWiseData from './WardWiseData/WardWiseData';
const Home = ({city}:{city:string}) => {
  return (
    <div className=''>
        <div className='mt-6'>
        <CityWiseData city={city}/>
        </div>
        <WardWiseData city={city}/>
        <BinCleanupData city={city}/>
        <MaterialTransferData city={city}/>
    </div>
  )
}

export default Home