import React,{useEffect, useState} from 'react'
import { DropDown } from './Ui-Elemnent/DropDown';
import Home from './Dashboard/Home';
// import useUserData from '../../../../Data/userData';
import useCity from '../Common/cityData';
import { Route, Routes } from 'react-router-dom';
const Insights = () => {
  const [selectCity, setSelectCity] = useState("Select City");
  const [list, setList]= useState<any[]>([]);
  const {cityList} = useCity();

  useEffect(()=>{
  setList(cityList)
  },[cityList]);

  useEffect(()=>{
    setSelectCity(list[0])
  },[list])

  return (
    <div className=' mt-4 relative'>
      <div className='flex justify-between mt-16  mx-8 '>
        {/* <h3 className={`${selectedTab === "Fees Collection" ? "text-xl":"text-3xl"}`}>{selectedTab}</h3> */}
        <h2>Insights</h2>
        <div className=''>
          <p className='text-md text-start mb-2'>City : { <DropDown list={list} selected={selectCity} setSelected={setSelectCity}/> }
          {/* <CitySelection /> */}
          <span>
            
          </span>
          
          </p>
        </div>
      </div>

      <div className=''>
      {<Home city={selectCity}/> }
            </div>
    </div>

  )
}

export default Insights