
import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_PRO_URL;

const useCustomerDetails = () => {
  const [materialWiseCustomerData, setMaterialWiseCustomerData] = useState<{labels: any[]; datasets: any[]}>({
    labels:[],
    datasets: [ ],
  });
  const [customerTypeData, setCustomerTypeData] = useState<{labels: any[]; datasets: any[]}>({
    labels:[],
    datasets: [ ],
  });

  // const labels:string[] = [];
  const [customerData, setCustomerData] = useState<any>({});
  const [verifiedCustomer, setVerifiedCustomer] = useState<any[]>([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/api/v1/dashboard/basecamp-customer`,
          {
            headers: {
              "Content-Type": "application/json",
              mode: "cors",
            },
          }
        );

        const result = await response.json();
        const data:any = result?.data;
        // console.log("data", data)
        // for(let item of data?.materialWiseData){
        //   labels.push(item?.materialType);

        // }
        let totalContracted:number = 0;
        let totalProcessed:number = 0;
        let totalRemaining:number = 0;
        let customerDetails:any = {};

        for(let item of data?.materialWiseData){
            totalContracted = totalContracted + item?.totalContracted;
            totalProcessed = totalProcessed + item?.totalProcessed;
            totalRemaining = totalRemaining + item?.totalRemaining

        }
        customerDetails.totalContracted = totalContracted;
        customerDetails.totalProcessed = totalProcessed;
        customerDetails.totalRemaining =  totalRemaining;
        customerDetails.totalCustomers = data?.totalCustomers?.contracts;

        

        
        if (!didCancel) {
          setCustomerData(customerDetails);
          setMaterialWiseCustomerData({
            labels: data?.materialWiseData?.map((obj:any) => obj?.materialType),
            datasets: [
              
              {
                label:  "Total Contracted",
                data: data?.materialWiseData?.map((obj:any) => obj?.totalContracted),//,
               borderColor: "#23418D",
               backgroundColor: "#23418D",
               maxBarThickness: 50
             },
             {
                label: "Total Processed",
                data: data?.materialWiseData?.map((obj:any) => obj?.totalProcessed),
                borderColor: "#FFE45C", 
                backgroundColor: "#FFE45C",
                maxBarThickness: 50
             },
             {
              label:  "Total Remaining",
              data: data?.materialWiseData?.map((obj:any) => obj.totalRemaining),
             borderColor: "#ADD8E6",
             backgroundColor: "#4CC6DE",
             maxBarThickness: 50
           },
            ],
          });
          
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(()=>{
    let didCancel = false;
    setError(null);
    (async () =>{
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/api/v1/dashboard/type-customer`,
          {
            headers: {
              "Content-Type": "application/json",
              mode: "cors",
            },
          }
        );

        const result = await response.json();
        // const data:any = result?.data;
        // console.log("data", result);
        const verificationData = [result?.NumberOfVerifiedCustomer, result?.NumberOfUnverifiedCustomer]
        setVerifiedCustomer(verificationData);
        const customerDataArr = [
          {type:"Producer",
            countPercentage: result?.ProducerCountinPercentage
          },
          {type:"Brand Owner",
          countPercentage: result?.BrandOwnerCountinPercentage
        },
        {type:"Manufacturer",
        countPercentage: result?.ManufactureCountinPercentage
      },
      {type:"Other",
      countPercentage: result?.OtherCountinPercentage
    },
        ];

        // console.log("customerDataArr", customerDataArr)
       
        if (!didCancel) {
          setCustomerTypeData({
            labels: customerDataArr?.map((obj:any) => obj?.type),
            datasets: [
              
              {
                label:  "",
                data: customerDataArr?.map((obj:any) => obj?.countPercentage),
               borderColor: "#23418D",
               backgroundColor: [ "blue", "pink", "yellow", "green"],
               maxBarThickness: 50
             },
            
            ],
          });
          
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  },[])



  return {  customerData, materialWiseCustomerData, customerTypeData, verifiedCustomer, loading, error };
};

export default useCustomerDetails ;
